const messages = {
  en: {
    'Month.January': 'January',
    'Month.February': 'February',
    'Month.March': 'March',
    'Month.April': 'April',
    'Month.May': 'May',
    'Month.June': 'June',
    'Month.July': 'July',
    'Month.August': 'August',
    'Month.September': 'September',
    'Month.October': 'October',
    'Month.November': 'November',
    'Month.December': 'December',

    'Currency.name': 'Dollar',
    'Currency.symbol': '$',
    'Currency.items': 'items',
    'Currency.pointsName': 'points',

    'Footer.PrivacyPolicyLocale': 'Privacy Policy',
    'Footer.TermsAndConditionsLocale': 'Terms and Conditions',
    'Footer.Release': 'Release',
    'Footer.CookieNotice': 'Cookie Notice',

    'Error.userEmailCantSend': 'Email cannot be sent',
    'Error.userRegisterError':
      'Your request cannot be completed. Please try again. If it continues to happen, please contact the support team.',
    'Error.userRegisterConflict':
      'Your registration could not be processed, there is a conflict in the data.',
    'Error.userProfileError':
      'Your profile could not be updated, please check for errors and try again.',
    'Error.userUpdatePasswordError':
      'There was a problem changing your password. Please double check your current password.',
    'Error.changePreferStoreError':
      'There was a problem changing your store. Please check and try again.',
    'Error.userLoginError': 'Your email or password could not be validated, please try again.',
    'Error.userForgotPassword':
      'If you have an account with us an e-mail has been sent to the address provided. Please check the e-mail for further instructions.',
    'Error.userResetPassword': 'Reset email has failed.',
    'Error.userUserData': 'Error getting user data',
    'Error.userConflict': 'Conflict',
    'Error.userGeneral': 'Error',
    'Error.IncorrectEmail': 'Incorrect username or password',
    'Error.currentPwdWrong': 'The current password you entered is incorrect. Please try again',

    'Error.fieldValidationPasswordDoNotMatch': 'Passwords do not match',
    'Error.fieldValidationCheckTheForm': 'Please check the values of the form',
    'Error.fieldValidationEmptyField': 'Field must not be blank',
    'Error.fieldValidationFirstNameCharLimit': 'First name must be less than 255 characters',
    'Error.fieldValidationLastNameCharLimit': 'Last name must be less than 255 characters',
    'Error.fieldValidationBirthday': 'Birthday can not be today, please change to correct day',
    'Error.fieldValidationBadEmailFormat': 'Incorrect email format',
    'Error.fieldValidationPasswordLength': 'Password must be at least 8 characters.',
    'Error.fieldValidationPhoneDigits': 'Phone must be 10 digits',
    'Error.fieldValidationPhoneOnlyDigits': 'Field can only contain numbers',
    'Error.fieldValidationBadPhoneformat': 'Incorrect phone format',
    'Error.fieldValidationAddressLength': 'Address must be less than 255 characters',
    'Error.fieldValidationCityLength': 'City must be less than 255 characters',
    'Error.fieldValidationZipLength': 'Zip code does not meet the required number',
    'Error.invalidDate': 'Invalid Date',
    'EmptyField.password': 'Please enter your password',
    'mptyField.phoneNumber': 'Please enter your password phone or email',
    'Error.emailrequired': 'Email adress is required',
    'MyAccount.Error.fieldValidationPasswordLength': 'Password must be at least 8 characters.',
    'MyAccount.Account.ConfNewPwdErrorMsg': 'Password does not match the new password',

    'Error.passwordChangingEmail':
      'There was an error changing your password. Please click the link from the email again.',
    'Error.passwordChanging': 'Please check the password fields for errors',

    'Error.cashBackPostingPayment':
      'This transaction could not be completed. Please try again later.',
    'Error.offerDetailsNotFound': 'This coupon is currently unavailable',
    'Error.offerExpired': 'The offer requested is not available',
    'Error.inValidfield': 'Invalid parameter',

    'Success.cashBack': 'Success! Your cash has been transferred.',
    'Success.passwordUpdate': 'Your password has been updated successfully!',
    'Success.passwordReset': 'Your password has been reset. Please login now.',
    'Success.passwordEmailResetSent':
      'If you have an account with us an e-mail has been sent to the address provided. Please check the e-mail for further instructions.',

    'Button.signIn': 'SIGN IN',
    'Button.login': 'LOGIN',
    'Button.reset': 'RESET',
    'Button.update': 'UPDATE',
    'Button.logout': 'LOGOUT',
    'Button.cancel': 'CANCEL',
    'Button.confirm': 'CONFIRM',
    'Button.register': 'REGISTER',
    'Button.transfer': 'TRANSFER',
    'Button.continue': 'CONTINUE',
    'Button.createWallet': 'CREATE EWALLET',
    'Button.send': 'SEND',
    'Button.forgotPassword': 'FORGOT PASSWORD',
    'Button.toTop': 'BACK TO TOP',
    'Button.cashBack': 'get cash back',
    'Button.save': 'save',
    'Button.clipReward': 'clip reward',
    'Button.clipCoupon': 'clip coupon',
    'Button.clippedCoupon': 'clipped',
    'Button.clippedWallet': 'clipped coupon',
    'Button.clippedWalletCashBack': 'clipped cash back',
    'Button.enroll': 'enroll',
    'Button.loading': 'loading...',
    'Button.enrolled': 'enrolled',
    'Button.done': 'Done',
    'Button.updatePassword': 'Update Password',
    'Button.UpdatePreferStore': 'Update My Preferred Store',
    'ButtonCash.cancel': 'Cancel',
    'ButtonCash.confirm': 'Confirm',
    'ButtonCash.next': 'Next',
    'Button.Coupon': 'Coupon',
    'Button.cashback': 'Cash Back',
    'Button.showChangeStoreOption': 'Change Store',
    'Button.channgePreferStoreLabel': 'Find Store Near By',
    'Button.yes': 'Yes',
    'Modal.finePrint': 'FINE PRINT',
    'InputField.password': 'Password',

    'Splash.saveUpTo': 'SAVE UP TO ',
    'Splash.today': 'TODAY',
    'Splash.with': 'WITH',
    'Splash.digitalCoupons': 'DIGITAL COUPONS!',
    'Splash.alreadyHaveAnAccount': 'Already have an Account?',
    'Splash.emptyText': 'There are currently no offers available.',

    'Login.login': 'Login',
    'Login.enterInfo':
      'Enter your information below to check out all your great personalized offers.',
    'Login.anyField': 'Any field with an asterisk (*) is considered required.',
    'Button.signUp': 'SIGN UP',
    'login.signIn': 'Sign In',
    'login.dontAccount': "Don't have an account?",
    'login.createAccount': 'Create an Account',

    'Account.yourAccount': 'Your Account',
    'Account.profile': 'PROFILE',
    'Account.editAccountInformation': 'EDIT ACCOUNT INFO',
    'Account.accountPassword': 'ACCOUNT PASSWORD',
    'Account.updatePassword': 'UPDATE PASSWORD',
    'Account.transactionHistory': 'HISTORY',
    'Account.viewYourTransactionHistory': 'VIEW YOUR HISTORY',
    'Account.version': 'Version',

    'Account.ChangePwd': 'Password',
    'Account.CurrentPassword': 'Current Password',
    'Account.NewPassword': 'New Password',
    'Account.ConfirmNewPassword': 'Confirm New Password',
    'Account.ConfNewPwdErrorMsg': 'Password does not match the new password',
    'Account.PreferredStore': 'Preferred Store',

    'BurgerMenu.totalAvailableSavings': 'Total Available Savings',
    'BurgerMenu.totalClippedSavings': 'Total Clipped Savings',
    'BurgerMenu.yearToDateSavings': 'Year To Date Savings',
    'BurgerMenu.myDigitalCoupons': 'Digital Offers',
    'BurgerMenu.coupons': 'Coupons',
    'BurgerMenu.club': 'Club',
    'BurgerMenu.allClubOffers': 'Available',
    'BurgerMenu.activeClubOffers': 'Enrolled',
    'BurgerMenu.myClubOffers': 'Earned Club Offers',
    'BurgerMenu.rewards': 'Rewards',
    'BurgerMenu.myClippedOffers': 'My Clipped Offers',
    'BurgerMenu.myEwallet': 'My eWallet',
    'BurgerMenu.helpfulLinks': 'Helpful Links',
    'BurgerMenu.profile': 'My Account',
    'BurgerMenu.personalInformation': 'Personal Information',
    'BurgerMenu.accountPassword': 'Account Password',
    'BurgerMenu.transactionHistory': 'Transaction History',
    'BurgerMenu.logout': 'Logout',
    'BurgerMenu.cashBack': 'Cash Back',
    'BurgerMenu.cashBackActive': 'Available Cash Back',
    'BurgerMenu.cashBackCashOut': 'Cash Out',
    'BurgerMenu.cashBackAccount': 'Cash Out Settings',

    'BurgerMenu.logoutModalAreYouSure': 'Are you sure you want to logout of your account',

    'ChangePassword.header': 'Account Password',
    'ChangePassword.subHeader':
      'Please note that the password information below pertains only to that used in affiliation with our customer reward program.',
    'ChangePassword.fineText':
      'All passwords must be at least 8 characters long. Any field with an asterisk (*) is considered required.',
    'ChangePassword.currentPassword': 'Current Password',
    'ChangePassword.newPassword': 'New Password',
    'ChangePassword.confirmPassword': 'Confirm Password',

    'Header.login': 'Login',
    'Header.hello': 'Hello',
    'Header.shopper': 'Shopper',
    'Header.your': 'Your',
    'Header.account': 'Account',

    'SearchBar.myAccount': 'Profile',
    'SearchBar.profile': 'My Account',
    'SearchBar.myEwallet': 'My eWallet',
    'SearchBar.coupons': 'Coupons',
    'SearchBar.clubOffers': 'Club Offers',
    'SearchBar.rewards': 'Rewards',
    'SearchBar.eWallet': 'My eWallet',
    'SearchBar.cashBack': 'Cash Back',
    'SearchBar.searchForTheOffers': 'Search for offers',

    'SearchBar.emailSending': 'Please wait while we are sending your email',
    'SearchBar.emailSent': 'Email has been sent, please check your inbox',

    'SubNavBar.selectOfferPool': 'Offer Group',
    'SubNavBar.selectViewBy': 'View By',
    'SubNavBar.selectEmptyText': 'Select...',
    'SubNavBar.selectOptionHighestValue': 'Highest Value',
    'SubNavBar.selectOptionNewest': 'Newest',
    'SubNavBar.selectOptionLowestValue': 'Lowest Value',
    'SubNavBar.selectOptionExpiring': 'Expiring',
    'SubNavBar.selectOptionCategory': 'Category',

    'SubNavBar.navigationGallery': 'Coupons',
    'SubNavBar.navigationEwallet': 'eWallet',
    'SubNavBar.navigationAllCubOffers': 'Available',
    'SubNavBar.navigationActiveClubOffers': 'Enrolled',
    'SubNavBar.navigationMyClubOffers': 'Earned',
    'SubNavBar.navigationPersonalInformation': 'Account Information',
    'SubNavBar.navigationAccountPassword': 'Account Password',
    'SubNavBar.navigationTransactionHistory': 'Transaction History',
    'SubNavBar.navigationClippedOffers': 'Clipped Offers',
    'SubNavBar.navigationCashBack': 'Available',
    'SubNavBar.navigationCashOut': 'Cash Out',
    'SubNavBar.navigationCashOutAccount': 'Cash Out Settings',

    'ForgotPassword.header': 'Forgot Your Password',
    'ForgotPassword.subHeader':
      "Enter your email below and we'll send you an email with the necessary steps to reset your password.",
    'ForgotPassword.fineText': 'Any field with an asterisk (*) is considered required.',
    'ForgotPassword.email': 'Email Address',
    'ForgotPassword.forgot': 'Forgot Password?',
    'Forgot.send': 'Send',

    'Profile.header': 'Account Information',
    'Profile.subHeaderStart': 'The information is only used by',
    'Profile.subheaderEnd': 'and will not be distributed to any third party.',
    'Profile.fineText': 'Any field with an asterisk (*) is considered required.',
    'Profile.updateSuccess': 'Your profile has been updated successfully!',
    'Profile.channgePreferStoreLabel': 'Find Store Near By',
    'Profile.preferredStoreDesc':
      'Offers and emails will reflect the savings and promotions available at your selected store',
    'Profile.preferStoresSearchEmptyMsg':
      'No Stores found with this address, Please try again with a different postal code',
    'Profile.channgePreferStorePlaceholder': 'Search by City or ZIP Code',
    'Profile.channgePreferStoreNoLocFoundMsg': 'No locations found nearby, showing the closest',

    'Recommended.justClipped': 'Just Clipped',
    'Recommended.youMightAlsoLike': 'You might also like',
    'Recommended.clip': 'Clip',
    'Recommended.clipped': 'Clipped',

    'UserHeader.totalAvailableSavings': 'Total Available Savings',
    'UserHeader.totalClippedSavings': 'Total Clipped Savings',
    'UserHeader.YTDSavings': 'Year To Date Savings',
    'UserHeader.AllClubSavings': 'All Club Offers Savings Available',

    'Savings.AvailableSavings': 'Available Savings',
    'Savings.ClippedSavings': 'Clipped Savings',
    'Savings.CashOutAvailable': 'Cash Out Available',
    'Savings.CashOutEarned': 'You have earned',
    'Savings.YeartoDateSavings': 'Year To Date Savings',
    'Savings.CashOut': 'Cash Out',

    'Register.howToClick': 'Click',
    'Register.howToClickText':
      'Once registered, you will be emailed weekly eCoupons. Digitally CLIP offers you want to load them to your personal eWallet.',
    'Register.howToShop': 'Shop',
    'Register.howToShopText':
      'Select your products and present your Shopper Id (phone number) at checkout every time you shop.',
    'Register.howToShopAccent': 'Clip while you shop!',
    'Register.howToSave': 'Save',
    'Register.howToSaveText':
      'eCoupon savings will be automatically deducted from your purchase subtotal.',
    'Register.howToSaveAccent': "It's that easy!",
    'Register.header': 'Create an Account',
    'Register.subHeader':
      'Thank you for registering! Please enter your information below to Sign Up for your personalized digital coupons. You must have a valid email to sign up. Keep an eye out for deals heading your way.',
    'Register.fineText': 'Any field with an asterisk (*) is considered required.',
    'Register.select': 'Select',
    'Register.required': 'is required',

    'ResetPassword.header': 'Reset Your Password',
    'ResetPassword.subHeader': 'Enter your new password below.',
    'ResetPassword.fineText': 'Any field with an asterisk (*) is considered required.',
    'ResetPassword.password': 'Password',
    'ResetPassword.confirmPassword': 'Confirm Password',
    'ResetPassword.errorPasswordLengthGreaterThan8': 'Password must be at least 8 characters',
    'ResetPassword.errorPasswordNotMatch': 'Password did not match',
    'ResetPassword.subText': 'Enter a new password below to change your password.',
    'ResetPassword.reset': 'Reset Password',
    'ResetPassword.backtoLogin': 'Back To Login',
    'ResetPassword.loginSuccess': 'You’re password has been changed successfully. ',
    'ResetPassword.headerLoginSuccess': 'Password Changed!',
    'ResetPassword.newPassword': 'New Password',

    'Rewards.totalRewardPoints': 'Total Reward Points',
    'Rewards.emptyText':
      'You have clipped all Reward offers! Please check back later for more reward offers.',
    'Rewards.bannerText': 'Points Reward',
    'Rewards.rewardBalance': 'Reward balance',
    'Rewards.pointsReward': 'Points Reward',

    'ClubOffers.pageTitle': 'Club Offers',
    'ClubOffers.reward': 'Reward',
    'AllClub.emptyText': 'You have enrolled in all club offers! View your Enrolled Clubs...',
    'ActiveClub.emptyText':
      'You have not enrolled in any club offers! Please check the Available Club section for available options.',

    'ActiveClub.usesAvailable': 'uses available',
    'ActiveClub.use': 'uses',
    'ActiveClub.timesPerTransaction': 'times per transaction',
    'ActiveClub.pointsName': 'POINTS',
    'ActiveClub.spendName': 'SPENT',
    'ActiveClub.itemsName': 'PURCHASED',

    'ClubOffers.pointsName': 'Points',
    'ClubOffers.completed': 'Completed',
    'ClubOffers.points': 'points',
    'ClubOffers.spendName': 'Spend',
    'ClubOffers.earn': 'Earn',
    'ClubOffers.itemsName': 'Purchase',
    'ClubOffers.locked': 'Locked',
    'ClubOffers.clipped': 'Claimed',
    'ClubOffers.available': 'Claim Reward',
    'ClubOffers.items': 'Items',
    'ClubOffers.clubProgressTitle': 'Club Progress',
    'ClubOffers.validDateLabel': 'Valid Until',
    'ClubOffers.redeemDateLabel': 'Redeem By',
    'ClubOffers.additionalDetailsTitle': 'Additional Details',
    'ClubOffers.rewardsTitle': 'Rewards',
    'ClubOffers.unlimitedUse': 'Unlimited Use',
    'ClubOffers.rewardsEarnedHash': '# of rewards earned',
    'ClubOffers.enroll': 'Enroll',
    'ClubOffers.enrolled': 'Enrolled',
    'ClubOffers.earnedRewardsTitle': 'Earned Rewards',
    'ClubOffers.NextReward': 'Next Reward',
    'ClubOffers.earned': 'Earned',
    'ClubOffers.claim': 'Claim Reward',

    'ClubOffers.RewardAvailable': 'Available',
    'ClubOffers.noRewardEmptyMsg': 'You do not have any rewards yet',
    'Cluboffers.earnedrewardLabel': 'Earned Reward',
    'Cluboffers.redemptionRequired': 'Redemption Required',
    'Cluboffers.redeemed': 'Redeemed ',
    'ClubOffers.rewardButton': 'Next Reward',
    'ClubOffers.clipRewardErrorMsg': 'Unable to clip the reward, Please contact the support team.',
    'ClubOffers.EarnedRewardDesc':
      "Claim your available rewards! To make the next reward available, all you need to do is redeem the reward you've already claimed. It's that simple!",
    'ClubOffers.date': 'Date',
    'ClubOffers.clubOffersEmptyMsgtext':
      'Start earning rewards now by participating in a club on the left. Select, shop, and track your progress towards exciting perks!',
    'ClubOffers.clubOffersEmptyMsg': 'Ways To Earn',
    'ClubOffer.expiring': 'EXPIRING SOON',
    'ClubOffer.additionalDetailsTitle': 'Additional Details',
    'ClubOffers.emptyClubOffers':
      'There are currently no Club Programs available. Please check back later',

    'MyClub.emptyText':
      'You have not clipped any club rewards! Please check the Enrolled Club section for available reward options.',

    'Transactions.Header': 'Transaction History',
    'Transactions.subHeader': 'Transactions may take up to 48 hours to post to your account.',
    'Transactions.emptyText':
      'You have no transactions available. Please redeem some offers then try again.',
    'Transactions.columnOffer': 'Offer',
    'Transactions.columnValue': 'Value',
    'Transactions.columnStatus': 'Status',
    'Transactions.columnDate': 'Date',

    'Wallet.tapText':
      "Don't miss out on the savings! Tap items to mark them off your shopping list.",
    'Wallet.emptyText':
      "Don't miss out on the savings! Check out our wonderful offers for more details.",
    'Wallet.viewTransactionsHistory': 'VIEW HISTORY',
    'Wallet.cashback': 'Clipped: Cash back',
    'Wallet.discount': 'Clipped: Coupon',

    'Offers.whenYouBuy': 'When you buy',
    'Offers.expires': 'Expires',
    'Offers.clipped': 'Clipped',
    'Offers.clippedUpper': 'CLIPPED',
    'Offers.couponDetails': 'coupon details',
    'Offers.clipIt': 'CLIP IT',
    'Offers.canBeUsed': 'Can be used up to',
    'Offers.times': 'times',
    'Offers.newDeal': 'NEW DEAL',
    'Offers.endingSoon': 'ENDING SOON',
    'Offers.congratulations': 'CONGRATULATIONS',
    'Offers.rewardAvailable': 'Reward offer is available for clipping',
    'Offers.unlimitedUse': 'UNLIMITED USE',
    'Offers.useUpTo': 'USE UP TO',
    'Offers.purchased': 'PURCHASED',
    'Offers.earned': 'Earned',
    'Offers.offerDetails': 'Offer Details',
    'Offers.statusAvailable': 'AVAILABLE',
    'Offers.statusClipped': 'CLIPPED',
    'Offers.statusRedeemed': 'REDEEMED',
    'Offers.statusExpired': 'EXPIRED',
    'Offers.points': 'Points',
    'Offers.endDate': 'Earn through: ',
    'Offers.expirationDate': 'Redeem through: ',
    'Offers.featuredOffersUnavailable': 'You have clipped all the offer(s) under this campaign!',
    'Offers.additionalDetails': 'Additional Details',
    'Offers.hoverText': 'Flex offer',
    'Offers.clipBefore': 'Clip before',
    'Offers.redeemFrom': 'Redeem from',
    'Offers.sortBy': 'Sort by',

    'OffersGallery.weeklySalesLink': 'Click here for your weekly sales!',
    'OffersGallery.badSearch': "We couldn't find what you're looking for. Please check back later.",
    'OffersGallery.allClipped':
      'You have clipped all offers! Please check back later for more offers.',
    'OffersGallery.categoryAllClipped':
      'You have clipped all offers in this category! Please check back later for more offers.',
    'OffersGallery.categoryBadSearchStart': "We couldn't find anything with the keyword",
    'OffersGallery.categoryBadSearchEnd': 'in this category.',

    'Category.produce': 'Produce',
    'Category.pet': 'Pet Supplies',
    'Category.beverage': 'Beverage',
    'Category.dairy': 'Dairy',
    'Category.deli': 'Deli',
    'Category.frozen': 'Frozen',
    'Category.general': 'General',
    'Category.grocery': 'Grocery',
    'Category.meat': 'Meat & Seafood',
    'Category.packaged_meat': 'Packaged Meat',
    'Category.snacks': 'Snack & Candy',
    'Category.beauty': 'Health & Beauty',
    'Category.entertainment': 'Entertainment',
    'Category.books': 'Books & Magazines',
    'Category.gifts': 'Flowers & Gifts',
    'Category.auto': 'Automotive',
    'Category.office': 'Office Supply',
    'Category.toys': 'Toys & Games',
    'Category.baby': 'Baby',
    'Category.bakery': 'Bakery',
    'Category.cereal': 'Breakfast & Cereal',
    'Category.soups': 'Canned Goods & Soups',
    'Category.international': 'International',
    'Category.home': 'Home',
    'Category.pharmacy': 'Pharmacy',
    'Category.alcohol': 'Wine, Beer & Spirits',
    'Category.tobacco': 'Tobacco',
    'Category.organic': 'Organic',
    'Category.aarp': 'AARP',
    'Category.special': 'Special',
    'Category.baking': 'Baking',
    'Category.kosher': 'Kosher',
    'Category.featured': 'Featured Offer',
    'Category.apparel': 'Apparel',
    'Category.electronics': 'Electronics',
    'Category.restaurant': 'Restaurant',
    'Category.travel': 'Travel',
    'Category.gift_card': 'Gift Card',

    'CashOutAccount.header': 'Add Account',
    'CashOutAccount.subText':
      'Add another account to your profile by entering the information below.',
    'CashOutAccount.create': 'Create',
    'CashOutAccountAdd.loyaltyId': 'Loyalty Id or Card Number',
    'CashOutAccountAdd.email': 'Email Address',
    'CashOutAccountAdd.description': 'Description',
    'CashOutAccountAdd.enabled': 'Enable',
    'CashOutAccountUpdate.header': 'Update Accounts',
    'CashOutAccountUpdate.subText': 'You can enable/disable your accounts bellow.',
    'CashOutAccountUpdate.tableColumnHeaderType': 'Type',
    'CashOutAccountUpdate.tableColumnHeaderUserName': 'Email/ID',
    'CashOutAccountUpdate.tableColumnHeaderDescription': 'Description',
    'CashOutAccountUpdate.tableColumnHeaderAction': 'Action',
    'CashOutAccountUpdate.enable': 'ENABLE',
    'CashOutAccountUpdate.disable': 'DISABLE',
    'CashOutAccountConfirm.message': 'Are you sure you want to',
    'CashOutAccountConfirm.the': 'the',
    'CashOutAccountConfirm.account': 'account',
    'CashBackActive.empty':
      'There are currently no cash back offers. Please check back in the future for more offers.',
    'CashBack.totalsHeader': 'Available to Cash Out for all your accounts',
    'CashBack.totalsHeader2': 'Which account would you like to cash out to?',
    'CashBack.redemptionRedeem': 'REDEEM HERE',
    'CashBack.accountHeader': 'Select the account you want to use',
    'CashBack.accountNotFoundClick': 'Click',
    'CashBack.accountNotFoundHere': 'here',
    'CashBack.accountNotFoundToCreate': 'to create an account for this type',
    'CashBack.amountHeader': 'Selected Account',
    'CashBack.amountInputLabel': 'Amount to cash out',
    'CashBack.amountMaxValue': 'Maximum available for the account',
    'CashBack.confirmHeader':
      'Please review the information below and confirm that you would like to transfer the cash out amount to your selected account',
    'CashBack.confirmAccount': 'Selected Account',
    'CashBack.confirmAmount': 'Cash Out Amount',
    'CashoutConfirmation.review': 'Review and Transfer',
    'CashoutConfirmation.typeOfPayment.loyalty': 'Loyalty Card',
    'CashoutConfirmation.typeOfPayment.paypal': 'PayPal',
    'CashoutConfirmation.typeOfPayment.charity': 'Charity',
    'CashoutConfirmation.transferTo': 'Transfer {amount} to',
    'CashoutConfirmation.successfullyTransfer': 'Successfully Transferred {amount} to',
    'CashoutConfirmation.transferFailed': 'Transfer Failed: ',
    'CashoutConfirmation.amount': 'Amount',
    'CashoutConfirmation.edit': 'Edit',
    'CashoutButtonGrid.transfer': 'Transfer All',

    'Group.empty':
      'There are currently no group offers. Please check back in the future for more offers.',
    'Group.clipAll': 'Please clip all offers below',
    'Group.clipOne': 'Please choose one of the offers below',
    'Group.clipMoreOffers': 'Clip More Offers',

    'OfferGroup.default': 'Default',
    'OfferGroup.relevancy': 'Relevancy',

    'Navigation.gallery': 'Coupons',
    'Navigation.allclub': 'Club Offers',
    'Navigation.reward': 'Rewards',
    'Navigation.wallet': 'Wallet',
    'Navigation.profile': 'My Account',

    'GalleryWrapper.digital': 'Digital Coupons',
    'GalleryWrapper.coupon': 'Coupon',
    'GalleryWrapper.coupons': 'Coupons',
    'GalleryWrapper.flex': 'How do you want to see your Flex Offers?',
    'GalleryWrapper.flexoffer': 'Flex Offer',
    'GalleryWrapper.flexcashlater': 'Cash Later',
    'GalleryWrapper.flexcashback': 'Cash Back',
    'GalleryWrapper.flexsaving': 'Instant Savings',
    'CashoutWrapper.minTransfer': 'Min transfer: $20.00',
    'CashoutWrapper.currentBalance': 'Current balance: {amount}',
    // cashout wrapper select account
    'CashoutWrapper.loyaltyCardBalance': ' Loyalty Card Balance: {amount}',
    'CashoutWrapper.availableToCashOut': ' Available for Cash Out: {amount}',

    'WalletWrapper.title': 'Wallet',

    'CashoutWrapper.loading': 'Loading balances...',
    'CashoutWrapper.loyalty': 'Loyalty Card',
    'CashoutWrapper.paypal': 'PayPal',
    'CashoutWrapper.paypalEmailAddress': 'Paypal Email Address',
    'CashoutWrapper.charity': 'Donate to Charity',
    'CashoutWrapper.cashout': 'Cash Out',
    'CashoutWrapper.cardbalance': 'Current loyalty card balance',
    'CashoutWrapper.cashoutavail': 'Available for Cash Out',
    'CashoutWrapper.cashoutAvailable': 'Available to Cash Out to Loyalty Card',
    'CashoutWrapper.cashoutAvailablePaypal': 'Available to Cash Out to Paypal',
    'CashoutWrapper.selectaccount': 'Select an account',
    'CashoutWrapper.recent': 'Recent Transactions',
    'CashoutWrapper.noRecentEmail': 'No Recent Emails For Paypal Transactions.',

    'Filter.categories': 'Categories',
    'Filter.clear': 'Clear',
    'Filter.viewResult': 'View Results',

    'Skip.content': 'Skip to main content',

    'Badge.new': 'NEW',
    'Badge.endingsoon': 'ENDING SOON',
    'Badge.expiring': 'EXPIRING',
    'Badge.startingsoon': 'STARTING SOON',
    'Badge.featured': 'FEATURED',

    'CouponHistory.redeemed': 'Redeemed',
    'CouponHistory.expired': 'Expired',
    'CouponHistory.title': 'Coupon History',
    'CouponHistory.offer': 'Offer',
    'CouponHistory.status': 'Status',
    'CouponHistory.brand': 'Brand',
    'CouponHistory.date': 'Date',
    'CouponHistory.print': 'Print',
    'register.alreadyAccount': 'Already have an account?',
    'register.createHeader': 'Create an Account',
    'register.enterInfo':
      'Thank you for registering! Sign Up below to receive personalized digital coupons. Valid email required. Look out for deals coming your way!',

    'MyAccount.title': 'My Account',
    'MyAccount.profile': 'Profile',
    'MyAccount.password': 'Password',
    'MyAccount.preferredstore': 'Preferred Store',
    'MyAccount.communityrewards': 'Community Rewards',
    'MyAccount.uselocation': 'Use Current Location',
    'MyAccount.signout': 'Sign Out',
    'MyAccount.helpful': 'Helpful Links',
    'MyAccount.profileRedirectBtn': 'View/Edit Profile',
    'MyAccount.manageProfileTitle': 'Manage your profile details',
    'MyAccount.manageProfileDesc1':
      'Clicking this link will redirect you to our centralized data service center.',
    'MyAccount.manageProfileDesc2':
      'Kindly remember to come back to this tab once you’ve finished making changes.',
    'MyAccount.subscribe_Unsubscibe_text': 'from promotional and marketing emails.',
    'MyAccount.Current Password': 'Please enter your Current Password',
    'MyAccount.New Password': 'Please enter your New Password',
    'MyAccount.Confirm New Password': 'Please enter your Confirm New Password',
    'Myaccount.preferedPlaceholder': 'Search by City or ZIP Code',

    'CreateAccount.marketing_text':
      'By clicking “Create An Account” you are agreeing to receive general promotional and marketing materials, via email. You can opt out of these preferences at any time from My Account.',
    'Profile.updateButton': 'Save Changes',
    'Profile.showmore': 'Show More',
    'Profile.showless': 'Show Less',

    'GuidedTour.welcome': 'Welcome',
    'GuidedTour.offerviewing': 'Offer Viewing Options',
    'GuidedTour.mywallet': 'My Wallet',
    'GuidedTour.tryitout': 'Try it Out',
    'GuidedTour.back': 'Back',
    'GuidedTour.next': 'Next',
    'GuidedTour.welcomecontent':
      'Now you have choices when it comes to redeeming your offers!  Flex Offers allow you to add a discount to your shopper account that can be redeemed the next time you shop, or a value that can be accrued and “cashed out” in the form of a discount to be applied towards a future purchase.',
    'GuidedTour.couponcontent':
      'A coupon is a discount that is applied instantly at the time of purchase.  Once the offer is clipped to your account, you may redeem it by making the required purchase and identifying yourself at checkout (e.g. enter phone number, scan loyalty card or bar code)',
    'GuidedTour.cashbackcontent':
      'A cash back offer will allow you to earn and accrue a discount to be used on a future purchase.  If selected, simply make the required purchase(s) and see your future savings add up in the “Cash Out” total.',
    'GuidedTour.flexoffercontent':
      'A flex offer puts the choice of how to redeem an offer in your hands.  You may choose an instant discount by selecting “Coupon” or select “Cash Back” and earn the discount to use at a later date',
    'GuidedTour.offerviewingcontent': 'View offers that match your redemption method preference.',
    'GuidedTour.cashoutcontent':
      'Total cash back accrued is visible here.  Click here and follow directions on the following screens to select the amount you’d like to cash out.',
    'GuidedTour.mywalletcontent':
      'You may view all clipped offers in your wallet.  Clips are separated by redemption method; coupons or cash back.',

    'UserName.updateSuccess': 'Your User Name has been updated successfully!',
    'Error.userNameError':
      'Your username could not be updated, please check for errors and try again.',
    'username.enterInfo':
      'The Save button will Save this value and redirect to the profile after successful updation.',

    'Print.title': ' Shopping List',
    'Print.subHeadertest': "Here's your ",
    'Print.search': 'Search',
    'Print.sort': 'Sort by',
    'Print.offers': 'Item(s) Details',
    'Print.totalcoupon': 'Total Coupons',
    'Print.category': 'Category',
    'Print.expiry': 'Expiry',
    'Print.expires': 'EXPIRES',
    'Print.emptyText': 'You have no clipped coupons. Please clip some coupons then try again.',

    'Pagetitle.Account': 'Account',
    'Pagetitle.AccountPassword': 'Account - Change Password',
    'Pagetitle.AccountProfile': 'Account - Profile',
    'Pagetitle.Signup': 'Signup',
    'Pagetitle.Resetpassword': 'Reset Password',
    'Pagetitle.Preferedstore': 'Account - Preferred Store',
    'Pagetitle.Cashout': 'Cashout',
    'Pagetitle.Cluboffers': 'Club Offers',
    'Pagetitle.Couponhistory': 'Coupon History',
    'Pagetitle.Print': 'Print Coupons',
    'Pagetitle.Gallery': 'Coupon Gallery',
    'Pagetitle.Forgot': 'Forgot Password',
    'Pagetitle.Signin': 'Signin',
    'Pagetitle.Splash': 'Splash',
    'Pagetitle.UsernameUpdate': 'Account - Update Username',
    'Pagetitle.Wallet': 'EWallet',
    'Pagetitle.Rewards': 'Rewards',
    'Pagetitle.Fueloffers': 'Fuel Offers',

    'Rewards.Rewards': 'Rewards',
    'Rewards.expires': 'Expires: ',
    'Rewards.locked': 'Locked',
    'Rewards.Amount_Spent': 'Amount Spent',
    'Rewards.Points_Earned': 'Points Earned',
    'Rewards.Item_Purchased': 'Items Purchased',
    'Rewards.available_to_claim': 'Available to claim',
    'Rewards.Rewards_Claimed': 'Rewards Claimed',
    'Rewards.view_rewards': 'View All Rewards',
    'Rewards.claim': 'Claim',
    'Rewards.Item': 'Items',
    'Rewards.Points': 'Points',
    'Rewards.Spent': 'Spent',
    'Rewards.loyaltyProgramEmptyMsg': 'Welcome to Loyalty Programs',
    'Rewards.loyaltyProgramEmptyMsgText': 'Welcome to Loyalty Programs. ',
    'Rewards.waysToEarnTitle': 'Ways to Earn',
    'Rewards.spentReward': 'Spent Reward',
    'Rewards.itemsReward': 'Items Reward',
    'Rewards.pointReward': 'Points Reward',
    'Rewards.welcomeMsg': 'Welcome to ',
    'Rewards.loyaltyProgram': 'Rewards Loyalty Program',
    'Rewards.loyaltyEarnpointsTxt':
      'Earn points on select purchases to spend on a variety of rewards!',
    'Rewards.loyaltyEmptyProgram':
      'There are currently no Reward Programs available. Please check back later',

    'Language.English': 'English (US)',
    'Language.French': 'Français (FR)',
    'Language.Spanish': 'Español (MX)',

    'FuelOffer.rewardTab': 'Rewards',
    'FuelOffer.fuelTab': 'Fuel Rewards',
    'FuelOffer.earnedLabel': 'Earned',
    'FuelOffer.redeemedLabel': 'Redeemed',
    'FuelOffer.expiredLabel': 'Expired',
    'FuelOffer.earnedon': 'Earned on ',
    'FuelOffer.expireson': 'Expires on ',
    'FuelOffer.redeemedon': 'Redeemed on ',
    'FuelOffer.expired': 'Expired on ',
    'FuelOffer.leftTitle': 'Fuel Rewards',
    'FuelOffer.points': 'Points',
    'FuelOffer.nextRewardTxt': 'Cents Off per gallon',
    'FuelOffer.save': 'Save',
    'FuelOffer.fuelPoints': 'Fuel Points',
    'FuelOffer.more': 'more points for an additional',
    'FuelOffer.cent': 'cents off per gallon!',
    'FuelOffer.centOff': 'cents off',
    'FuelOffer.description':
      'Past 31 days of transactions are shown, Fuel Points expire after 30 days',
    'FuelOffer.availablesavings': 'Available savings per gallon',
    'FuelOffer.Earn': 'Earn',
    'FuelOffer.noRewards': 'No Rewards Currently Available',

    'CommunityReward.enrolled': 'Enrolled',
    'CommunityReward.enroll': 'Enroll',
    'CommunityReward.viewWeb': 'View Website',
    'CommunityReward.rightTitle': 'Community Rewards',
    'CommunityReward.search': 'Search',
    'CommunityReward.modalAreYouSure':
      'Choosing a different organization will replace your existing organization.',
    'CommunityReward.confirmationTxt': 'Do you want to continue?',
    'CommunityReward.noEnrollMsg':
      'You are not enrolled in any community program. Please enroll to start your participation',
  },
};

export default Object.freeze({ ...messages });
